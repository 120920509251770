import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission } from '@app/models/objects/permission';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`property/${propertyId}/acl/available_permissions`);
  }

  loadTranslation(propertyId: number) {
    return this.http.get(`property/${propertyId}/acl/permissions_labels`);
  }

  setUserPermissions(
    property_id: number,
    user_id: number,
    permissions: Permission,
  ) {
    return this.http.post(
      `property/${property_id}/acl/user/${user_id}/set_permissions`,
      { property_id, user_id, permissions },
    );
  }
}
