import {
  Permission,
  PermissionTranslations,
} from '@app/models/objects/permission';

export interface State {
  isLoading?: boolean;
  permissions: Permission;
  translations: PermissionTranslations;
}

export const initialState: State = {
  isLoading: false,
  permissions: null,
  translations: null,
};
