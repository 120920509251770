import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { PermissionsService } from '@app/services/permissions.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class PermissionsStoreEffects {
  constructor(
    private dataService: PermissionsService,
    private actions$: Actions,
    private store: Store<{ core: ICoreState }>,
  ) {}

  setUserPermissionsEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SetUserPermisssionsRequestAction>(
        featureActions.ActionTypes.SET_USER_PERMISSIONS_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.SetUserPermisssionsRequestAction,
          { core: ICoreState },
        ]) => {
          return this.dataService
            .setUserPermissions(
              action.payload.propertyId,
              action.payload.userId,
              action.payload.permissions,
            )
            .pipe(
              map(
                ({ data }: any) =>
                  new featureActions.SetUserPermisssionsSuccessAction(),
              ),
              catchError((error) =>
                of(
                  new featureActions.SetUserPermisssionsFailureAction({
                    error,
                  }),
                ),
              ),
            );
        },
      ),
    ),
  );

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadRequestAction,
          { core: ICoreState },
        ]) => {
          return this.dataService.load(action.payload.propertyId).pipe(
            map(
              ({ data }: any) =>
                new featureActions.LoadSuccessAction({
                  items: data,
                }),
            ),
            catchError((error) =>
              of(new featureActions.LoadFailureAction({ error })),
            ),
          );
        },
      ),
    ),
  );

  loadTranslationsRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadTranslationsRequestAction>(
        featureActions.ActionTypes.LOAD_TRANSLATIONS_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadTranslationsRequestAction,
          { core: ICoreState },
        ]) => {
          return this.dataService
            .loadTranslation(action.payload.propertyId)
            .pipe(
              map(
                (response: any) =>
                  new featureActions.LoadTranslationsSuccessAction({
                    items: response.data,
                  }),
              ),
              catchError((error) =>
                of(new featureActions.LoadTranslationsFailureAction({ error })),
              ),
            );
        },
      ),
    ),
  );
}
